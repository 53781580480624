<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"></loading>
    <Header/>
    <section class="main vacancy" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="container-fluid p-4">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div v-if="!isLoading" class="col-12 mb-4">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item text-primary"><a href="/MyVacancy/published">ვაკანსიების სტატუსი</a></li>
                <li class="breadcrumb-item" aria-current="page">
                  <a :href="'/Candidats/'+this.$route.params.id">
                  {{ result_data.position.name }}</a>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                  <span v-if="this.$route.params.status=='won'">აყვანილი კანდიდატები</span>
                  <span v-if="this.$route.params.status=='closing'">საუკეთესო კანდიდატები</span>
                  <span v-if="this.$route.params.status=='meeting'">შესახვედრი კანდიდატები</span>
                  <span v-if="this.$route.params.status=='offers'">განსახილველი კანდიდატები</span>
                  <span v-if="this.$route.params.status=='rejects'">უარყოფილი კანდიდატები</span>
                </li>
              </ol>
            </nav>
          </div>
          <!-- End Col -->

          <!-- Col -->
          <div v-for="(item, index) in LoadVacancy" class="col-12 col-xl-6 mb-3">
            <div class="card">
              <div class="card-header d-flex">
                <small class="text-muted"><b>ID:</b> {{  item.id }}</small>
                <small class="text-muted ms-auto" title="დამატების თარიღი"> {{ item.inserted_at }}</small>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6 col-xl-6">
                      <b v-b-tooltip.hover.top="'პოზიციის დასახელება'">{{ item.name }}</b>
                      <small class="text-muted d-block" title="აპლიკანტის ნომერი"> {{ item.mobile }}</small>
                  </div>
                  <div class="col-6 col-xl-6 mt-0 mt-md-0 text-end text-md-end">


                    <b-button-group >
                      <b-dropdown v-if="item.offer_status=='offers'" id="dropdown"  text="სტატუსი" variant="primary">
                        <b-dropdown-item @click="OfferStatus(item.id,'meeting')">
                          <i class="tio-calendar-month text-success"></i>
                          შეხვედრის დანიშვნა
                        </b-dropdown-item>
                        <b-dropdown-item @click="OfferStatus(item.id,'rejects')">
                          <i class="tio-clear-circle-outlined text-danger"></i>
                          უარი
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown v-if="item.offer_status=='rejects'" id="dropdown"  text="სტატუსი" variant="primary">
                        <b-dropdown-item @click="OfferStatus(item.id,'meeting')">
                          <i class="tio-calendar-month text-success"></i> შეხვედრის დანიშვნა
                        </b-dropdown-item>
                        <b-dropdown-item @click="OfferStatus(item.id,'rejects')">
                          <i class="tio-clear-circle-outlined text-danger"></i>
                          უარი
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown v-if="item.offer_status=='meeting'" id="dropdown"  text="სტატუსი" variant="primary">
                        <b-dropdown-item @click="OfferStatus(item.id,'closing')">
                          <i class="tio-calendar-month text-success"></i> ბოლო ეტაპზე გადაყვანა
                        </b-dropdown-item>
                        <b-dropdown-item @click="OfferStatus(item.id,'rejects')">
                          <i class="tio-clear-circle-outlined text-danger"></i>
                          უარი
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-dropdown v-if="item.offer_status=='closing'" id="dropdown"  text="სტატუსი" variant="primary">
                        <b-dropdown-item @click="OfferStatus(item.id,'won')">
                          <i class="tio-calendar-month text-success"></i> სამსახურში აყვანა
                        </b-dropdown-item>
                        <b-dropdown-item @click="OfferStatus(item.id,'rejects')">
                          <i class="tio-clear-circle-outlined text-danger"></i>
                          უარი
                        </b-dropdown-item>
                      </b-dropdown>
                      <a :href="item.attachment" target="_blank" class="btn btn-outline-secondary text-nowrap px-1 px-md-3"><i class="fa fa-eye"></i> CV</a>
                    </b-button-group>




                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Col -->



        </div>
        <!-- End Row -->
      </div>


    </section>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
export default {
  name: "Candidat",
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      name: '',
      nameState: null,
      submittedNames: [],
      isLoading: false,
      fullPage: true,
      isActive: true,
      LoadVacancy:[],
      result_data:{
        IndustryDetail:{
          text:'',
          id:''
        }
      }
    }
  },
  components: {
    Header,
    Loading
  },
  methods: {

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    LoadGetById(){

      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/vacancy/get_by_vacancy_id/'+this.$route.params.id+'/'+this.$route.params.status,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.LoadVacancy=response.data;
          });

    },
    OfferStatus(id,offer_status){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/vacancy/offer_status/'+id+'/'+offer_status,{},{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.LoadGetById();
            this.isLoading = false;
          });
    },
    getVacancyById(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/vacancy/get_by_id/'+this.$route.params.id,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.result_data=response.data;
          });
    }

  },
  mounted() {
    this.LoadGetById();
    this.getVacancyById();
    this.isLoading = true;
    this.showTopMenu=localStorage.getItem('showTopMenu');
    setTimeout(() => {
      this.isLoading = false
    }, 300);
  },
}
</script>

<style scoped>

</style>
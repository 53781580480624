<template>
  <div>
    <Header/>
    <section class="main vacancy" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="container-fluid p-4">
        <!-- Row -->
        <div class="row">
          <!-- Col -->
          <div class="col-12 mb-4">
            <h1 class="page-title">ვაკანსიის დამამტება</h1>
          </div>
          <!-- End Col -->

          <ValidationObserver v-slot="{ handleSubmit }">
          <form v-if="permissions.vacancy_permission=='enabled'"   @submit.prevent="handleSubmit(submitForm)" class="rowpt-70">
          <!-- Col -->
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form class="row">
                  <!-- Col -->

                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Location" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" for="location">ქალაქი</label>
                    <v-select v-model="form.location" class="lss-form-select" placeholder="აირჩიეთ ქალაქი" label="text" :options="LocationData"></v-select>
                    <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                    </ValidationProvider>

                  </div>

                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Work Schedule" rules="required" v-slot="{ errors }">
                      <label class="font-weigt-bold">სამუშაო გრაფიკი</label>
                      <v-select v-model="form.work_schedule" class="lss-form-select" placeholder="სამუშაო გამოცდილება"  :options="
                     [{ label: 'დისტანციური', id: 'remote' },
                     { label: 'სრული განაკვეთი', id: 'full' },
                     { label: 'ნახევარი განაკვეთი', id: 'half' }]"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->



                  <div class="form-group col-12">
                    <div class="card-header ps-0">
                      <div class="font-weigt-bold">მახასიათებლები</div>
                    </div>
                  </div>

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Position" rules="required" v-slot="{ errors }">
                      <label class="font-weigt-bold">პოზიციის დასახელება</label>
                      <v-select v-model="form.position" class="lss-form-select" placeholder="აირჩიეთ პოზიცია" label="text" :options="PositionData"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>

                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Categories" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold"> საქმიანობა <span class="text-secondary" style="font-size: 13px;font-weight: 500">( რომლის გაკეთებაც კარგად უნდა იცოდეს კანდიდატმა )</span> </label>
                    <v-select v-model="form.sphere" class="lss-form-select" placeholder="აირჩიეთ კატეგორია" label="text" :options="SphereData"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>

                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Industry" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold">ინდუსტრია</label>
                    <input type="text" class="form-control" v-model="form.industry" style="height: 53px;" placeholder="ჩაწერეთ ინდუსტრია">
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>

                  </div>
                  <!-- End Col -->

                  <!-- Col -->


                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Experience" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold">გამოცდილება</label>
                    <v-select v-model="form.work_experience" class="lss-form-select" placeholder="იგივე პოზიციაზე მუშაობის გამოცდილება"  :options="
                     [{ label: 'გამოცდილების გარეშე', id: 1 },
                     { label: 'დამწყები', id: 2 },
                     { label: 'საშუალო დონე', id: 3 },
                     { label: 'პროფესიონალი', id: 4 }]"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Company" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold">კომპანია</label>
                    <v-select v-model="form.detail_id" class="lss-form-select" placeholder="აირჩიეთ კომპანია" label="text" :options="DetailData"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->


                  <div class="form-group col-12 col-md-6">
                    <ValidationProvider name="Remuneration" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold">ანაზღაურება</label>
                    <v-select v-model="form.currency" class="lss-form-select" placeholder="აირჩიეთ ვალუტა" label="text" :options="CurrencyData"></v-select>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>

                  <!-- Col -->
                  <div class="form-group col-12 col-md-9">
                    <label class="font-weigt-bold d-block">ანაზღაურების ტიპები</label>
                    <div class="row" role="group" aria-label="Basic radio toggle button group">


                      <div class="col-12 col-md-4">
                      <input type="radio" class="btn-check show" name="salary" id="salary1" autocomplete="off" @click="salaryAmount('1')">
                      <label class="btn btn-outline-primary w-100" for="salary1">ფიქსირებული + ბონუსი</label>
                      </div>

                      <div class="col-12 col-md-4">
                      <input type="radio" class="btn-check" name="salary" id="salary2" autocomplete="off" @click="salaryAmount('2')" :class="{show:salary == '2'}">
                      <label class="btn btn-outline-primary w-100" for="salary2">გამომუშავებით</label>
                      </div>

                      <div class="col-12 col-md-4">
                      <input type="radio" class="btn-check" name="salary" id="salary3" autocomplete="off" @click="salaryAmount('3')" :class="{show:salary == '3'}">
                      <label class="btn btn-outline-primary w-100" for="salary3">მხოლოდ ფიქსირებული</label>
                      </div>
                      </div>


                  </div>
                  <!-- End Col -->


                  <!-- Col -->
                  <div class="form-group col-12" v-if="salary == '1'">
                    <!-- Card -->
                  <div class="card">
                    <div class="card-header bg-light">
                      <h5 class="card-title" >ფიქსირებული + ბონუსი</h5>
                    </div>
                    <div class="card-body">
                      <!-- Row -->
                      <div class="row">
                        <!-- Col -->
                        <div class="form-group col-12">
                          <label class="font-weigt-bold" for="vacancy-Variable-Fixed-amount">ფიქსირებული ხელფასი</label>
                          <input id="vacancy-Variable-Fixed-amount" onkeydown="return event.keyCode !== 69" v-model="form.fixed_amount" class="form-control" type="number" placeholder="ფიქსირებული ხელფასი">
                        </div>
                        <!-- End Col -->

                        <!-- Col -->
                        <div class="form-group col-6">
                          <label class="font-weigt-bold" for="vacancy-Fixed-Variable-min-amount">მინიმალური საშუალო ბონუსი (გამომუშავებული თანხა)</label>
                          <input id="vacancy-Fixed-Variable-min-amount" onkeydown="return event.keyCode !== 69" @keyup="change_min()" v-model="form.min_bonus" class="form-control" type="number" placeholder="-დან">
                        </div>
                        <!-- End Col -->

                        <!-- Col -->
                        <div class="form-group col-6">
                          <label class="font-weigt-bold" for="vacancy-Fixed-Variable-max-amount">მაქსიმალური საშუალო ბონუსი (გამომუშავებული თანხა)</label>
                          <input id="vacancy-Fixed-Variable-max-amount" onkeydown="return event.keyCode !== 69" @keyup="change_max()" v-model="form.max_bonus" class="form-control" type="number" placeholder="-მდე">
                        </div>
                        <!-- End Col -->

                        <!-- Col -->






                          <div class="form-group col-12 col-md-6">
                            <label class="font-weigt-bold">საშუალოდ მინიმალური შემოსავალი თვეში</label>
                            <input type="text" :value="minimum_amount"  disabled class="form-control">
                          </div>


                          <div class="form-group col-12 col-md-6">
                            <label class="font-weigt-bold">საშუალოდ მაქსიმალური შემოსავალი თვეში</label>
                            <input type="text" :value="maxsimum_amount" disabled class="form-control">
                          </div>

                        <!-- End Col -->

                        <!-- Col -->

                        <!-- End Col -->
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                    <!-- Card -->
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12" v-if="salary == '2'" >
                    <!-- Card -->
                  <div class="card">
                    <div class="card-header bg-light">
                      <h5 class="card-title" >გამომუშავებით</h5>
                    </div>
                    <div class="card-body">
                      <!-- Row -->
                      <div class="row">
                        <!-- Col -->
                        <div class="form-group col-6">
                          <label class="font-weigt-bold" for="vacancy-Variable-min-amount">მინიმალური საშუალო თანხა</label>
                          <input id="vacancy-Variable-min-amount" v-model="form.min_amount" onkeydown="return event.keyCode !== 69" class="form-control" type="number" placeholder="-დან">
                        </div>
                        <!-- End Col -->

                        <!-- Col -->
                        <div class="form-group col-6">
                          <label class="font-weigt-bold" for="vacancy-Variable-max-amount">მაქსიმალური საშუალო თანხა</label>
                          <input id="vacancy-Variable-max-amount"  v-model="form.max_amount" onkeydown="return event.keyCode !== 69" class="form-control" type="number" placeholder="-მდე">
                        </div>
                        <!-- End Col -->







                        <!-- End Col -->

                        <!-- Col -->

                        <!-- End Col -->
                      </div>
                      <!-- End Row -->
                    </div>
                  </div>
                    <!-- Card -->
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12" v-if="salary == '3'">
                    <!-- Card -->
                    <div class="card">
                      <div class="card-header bg-light">
                        <h5 class="card-title" >მხოლოდ ფიქსირებული</h5>
                      </div>
                      <div class="card-body">
                        <!-- Row -->
                        <div class="row">

                          <!-- Col -->
                          <div class="form-group col-12">
                            <label class="font-weigt-bold" for="vacancy-Fixed-amount">ფიქსირებული თანხა</label>
                            <input id="vacancy-Fixed-amount" onkeydown="return event.keyCode !== 69" v-model="form.fixed_amount" class="form-control" type="number" placeholder="ფიქსირებული თანხა">
                          </div>
                          <!-- End Col -->
                        </div>
                        <!-- End Row -->
                      </div>
                    </div>
                    <!-- Card -->
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <ValidationProvider name="Why Should" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" >რატომ უნდა იმუშაო ჩვენს კომპანიაში?</label>
                    <textarea class="form-control" v-model="form.why_should" type="text" placeholder="ჩაწერე ტექსტი"></textarea>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12" :class="checkRadio == '1'">
                    <ValidationProvider name="What we do" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" >რა უნდა გააკეთო?</label>
                    <textarea class="form-control"  v-model="form.what_to_do" type="text" placeholder="ჩაწერე ტექსტი"></textarea>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->


                  <!-- Col -->
                  <div class="form-group col-12">
                    <ValidationProvider name="What Will you teach" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" >რას გასწავლით?</label>
                    <textarea class="form-control" v-model="form.what_will_you_teach" type="text" placeholder="ჩაწერე ტექსტი"></textarea>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12 col-md-6">
                    <label class="font-weigt-bold d-block">იზომება თუ არა შენი მუშაობის შედეგები?</label>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                      <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off">
                      <label class="btn btn-outline-primary" for="btnradio1" @click="checkedRadio('1')">კი</label>

                      <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">
                      <label class="btn btn-outline-primary" for="btnradio2" @click="checkedRadio('2')">არა</label>
                    </div>
                  </div>
                  <!-- End Col -->


                  <div class="form-group col-12">
                    <label class="font-weigt-bold" >რა შედეგს უნდა მიაღწიო?</label>
                    <textarea class="form-control" v-if="form.result_to_work=='no'" disabled  type="text" placeholder="შედეგი არ იზომება."> შედეგი არ იზომება. </textarea>
                    <textarea class="form-control" v-else v-model="form.what_result_should_you_achieve" type="text" placeholder="ჩაწერე ტექსტი"></textarea>
                  </div>



                  <!-- Col -->
                  <div class="form-group col-12" v-if="checkRadio == '1'">
                    <div class="card">
                      <div class="card-header bg-light">
                        <h5 class="card-title" >როგორ იზომება?</h5>
                      </div>
                      <div class="card-body">
                        <!-- Row -->
                        <div class="row">

                          <!-- Col -->
                          <div class="form-group col-12">
                            <ValidationProvider name="How is it Measured" rules="required" v-slot="{ errors }">
                            <textarea class="form-control" type="text" v-model="form.how_is_it_measured" placeholder="ჩაწერე ტექსტი"></textarea>
                              <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                            </ValidationProvider>
                          </div>
                          <!-- End Col -->
                        </div>
                        <!-- End Row -->
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <ValidationProvider name="perspective" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" >რა პერსპექტივა გაქვს ჩვენს კომპანიაში?</label>
                    <textarea class="form-control" type="text" v-model="form.perspective" placeholder="ჩაწერე ტექსტი"></textarea>
                      <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>

                    </ValidationProvider>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <ValidationProvider name="requirement" rules="required" v-slot="{ errors }">
                    <label class="font-weigt-bold" >რა მოთხოვნებს უნდა აკმაყოფილებდე?</label>
                    <textarea class="form-control" type="text" v-model="form.requirement" placeholder="ჩაწერე ტექსტი"></textarea>
                    <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                    </ValidationProvider>

                  </div>
                  <!-- End Col -->



                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="font-weigt-bold">ვაკანსიის დასრულების ვადა</label>
                    <div class="col-12">
                      <div class="row">

                        <div class="col-12">
                          <ValidationProvider name="How is it Measured" rules="required" v-slot="{ errors }">
                          <date-picker class="form-control" v-model="form.end_at" valueType="format" placeholder="-მდე"></date-picker>
                            <span  class="text-danger" v-if="errors[0]">აუცილებელი ველი</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Col -->

                  <!-- Col -->
                  <div class="form-group col-12">
                    <label class="font-weigt-bold" >დამატებითი ჩასაწერი</label>
                    <textarea class="form-control" v-model="form.additional_info" type="text" placeholder="ჩაწერე ტექსტი"></textarea>
                  </div>
                  <!-- End Col -->
                </form>
              </div>
              <div class="card-footer py-4 bg-transparent">
                <button type="submit" class="btn btn-primary">ვაკანსიის დამატება +</button>
              </div>
            </div>
          </div>
          <!-- End Col -->
          </form>
          </ValidationObserver>
          <div class="alert alert-danger text-center" v-if="this.permissions.vacancy_permission=='disabled'" >
            იმისთვის რომ შეძლოთ ვაკანსის განთავსება ვებ გვერდზე საჭიროა შეავსოთ კომპანის რეკვზიტები  <a href="/Details/create">შემდეგ მისამართზე</a>
          </div>
        </div>
        <!-- End Row -->
      </div>
    </section>
  </div>

</template>

<script>
import Header from '@/components/Header.vue'
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddVacancy",
  data () {
    return {
      showTopMenu: true,
      selected: '',
      datePick: false,
      checkRadio: '',
      salary: '',
      time1: null,
      time2: null,
      time3: null,
      weekDays: ['ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი', 'კვირა'],
      selected_1: [],
      allSelected: false,
      indeterminate: false,
      CurrencyData:[],
      form:{
        salary:'',
        location:'',
        work_schedule:'',
        work_experience:'',
        time_at:'',
        time_to:'',
        sphere:'',
        position:'',
        industry:'',
        currency:'',
        amount_type:'',
        fixed_amount:'',
        min_bonus:'',
        max_bonus:'',
        margin:'',
        why_should:'',
        what_to_do:'',
        what_will_you_teach:'',
        what_result_should_you_achieve:'',
        how_is_it_measured:'',
        perspective:'',
        requirement:'',
        start_at:'',
        end_at:'',
        additional_info:'',
        detail_id:'',
        min_amount:'',
        max_amount:''
      },
      SphereData:[],
      LocationData:[],
      PositionData:[],
      IndustryData:[],
      DetailData:[],
      minimum_amount:'',
      maxsimum_amount:''
    }
  },
  components: {
    Header
  },
  methods: {
    toggleAll(checked) {
      this.selected_1 = checked ? this.weekDays.slice() : []
    },
    salaryAmount(index) {
      this.salary = index;
      this.form.amount_type=index;
    },
    checkedRadio(index) {
      this.checkRadio = index;
      if(index==1){
        this.form.result_to_work='yes';
      }else{
        this.form.result_to_work='no';
      }
    },
    switchSelect(event) {
      this.selected = event.target.value;
      this.datePick = !!event.target.value;
    },
    loadCurrency(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/LoadCurrency')
          .then(response => {
            this.CurrencyData=response.data;
          });
    },

    loadSphere(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/LoadSphere')
          .then(response => {
            this.SphereData=response.data;
          });
    },

    LoadLocation(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/LoadLocations')
          .then(response => {
            this.LocationData=response.data;
          });
    },

    loadPosition(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/LoadPosition')
          .then(response => {
            this.PositionData=response.data;
          });
    },

    loadIndustry(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/LoadIndustry')
          .then(response => {
            this.IndustryData=response.data;
          });
    },
    loadDetails(){
      const token = JSON.parse(localStorage.getItem('user')).access_token;
      axios.get(process.env.VUE_APP_BACKEND_URL+'/auth/details/get_all_details_option',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then(response => {
            this.DetailData=response.data;
          });
    },
    submitForm(){
      this.isLoading = true;
      const token = JSON.parse(localStorage.getItem('user')).access_token;

      axios.post(process.env.VUE_APP_BACKEND_URL+'/auth/vacancy/add_new_vacancy',{'data':this.form}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
          .then((res) => {
            this.isLoading = false;
            this.$router.push('/VacancyDashboard');
          })
          .catch((error) => {
            this.isLoading = false;


          });

    },
    change_min(){
      let calculatetotal=Number(this.form.fixed_amount)+Number(this.form.min_bonus);
      this.minimum_amount=calculatetotal;
    },
    change_max(){
      let calculatetotal=Number(this.form.fixed_amount)+Number(this.form.max_bonus);
      this.maxsimum_amount=calculatetotal;
    },

      ...mapActions({ addPermission: "setCurrentPermission" }),



  },
  mounted() {
    this.LoadLocation();
    this.loadDetails();
    this.loadCurrency();
    this.loadSphere();
    this.loadPosition();
    this.loadIndustry();
    this.showTopMenu=localStorage.getItem('showTopMenu');



  },
  computed: {
    ...mapGetters({ permissions: "getCurrentPermissions" }),


    // joke() {
    // return this.getCurrentJoke;
    // }
  },

  watch: {
    selected_1(newValue, oldValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.weekDays.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    }
  }
}
</script>

<style>

</style>
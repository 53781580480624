<template>
  <div>
    <Header/>
    <div class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="container-fluid p-4">
        <div class="card">
          <div class="card-body">
            <img alt="" class="rounded-3 mb-4" :src="form.file" height="200">

            <p class="card-text" v-html="form.text_back"></p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Header from '@/components/Header.vue'
import axios from "axios";
export default {
  name: "Dashboard",
  data () {
    return {
      form:{
        file: ''
      },
      showTopMenu: false
    }
  },
  components: {
    Header
  },
  methods:{
    LoadDesc(){
      axios.get(process.env.VUE_APP_BACKEND_URL+'/public/static/loadData')
          .then(response => {
            this.form=response.data;
          });
    }
  },
  mounted() {
    this.LoadDesc();
    this.showTopMenu=localStorage.getItem('showTopMenu');
  }
}
</script>

<style scoped>

</style>
<template>

</template>

<script>
import axios from "axios";

export default {
  name: 'Home',
  data () {
    return {

    }

  },

  components: {

  },
}
</script>

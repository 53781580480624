import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/auth/Home.vue'
import Signin from '../views/auth/Signin.vue'
import Signup from '../views/auth/Signup.vue'
import Recovery from '../views/auth/Recovery.vue'
import AddVacancy from '../views/auth/AddVacancy.vue'
import MyVacancy from '../views/auth/MyVacancy.vue'
import VacancyDashboard from '../views/auth/VacancyDashboard.vue'
import Details from '../views/pages/details/index.vue'
import CreateDetail from '../views/pages/details/Create.vue'
import DetailsEdit from '../views/pages/details/Edit.vue'
import CandidatsDashboard from '../views/auth/CandidatsDashboard.vue'
import Candidats from '../views/auth/Candidats.vue'
import PasswordChange from '../views/auth/profile/password_change.vue'
import VacancyEdit from '../views/auth/VacancyEdit.vue'
import Static from '../views/auth/Static.vue'
import AllCompany from '../views/auth/company/index.vue'
import CompanyEdit from '../views/auth/company/Edit.vue'
/* Pages/Types/Positions */
import PositionList from '../views/pages/types/Positions/Index.vue'
import AddPosition from '../views/pages/types/Positions/AddPosition.vue'
import positionEdit from '../views/pages/types/Positions/Details/Edit.vue'
/* Pages/Types/Jobs */
import jobList from '../views/pages/types/Jobs/Index.vue'
import AddJob from '../views/pages/types/Jobs/AddJob.vue'
import jobEdit from '../views/pages/types/Jobs/Details/Edit.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Home',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/Signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/Recovery',
    name: 'Recovery',
    component: Recovery
  },
  {
    path: '/MyVacancy/:param',
    name: 'MyVacancy',
    component: MyVacancy
  },
  {
    path: '/VacancyDashboard',
    name: 'VacancyDashboard',
    component: VacancyDashboard
  },
  {
    path: '/AddVacancy',
    name: 'AddVacancy',
    component: AddVacancy
  },
  {
    path:'/vacancy/:id/edit',
    name:'VacancyEdit',
    component: VacancyEdit
  },

  {
    path:'/Details',
    name:'Details',
    component: Details
  },
  {
    path:'/Details/create',
    name:'CreateDetail',
    component: CreateDetail
  },
  {
    path:'/Details/:id/edit',
    name:'DetailsEdit',
    component: DetailsEdit
  },

  {
    path: '/Candidats/:id/:status',
    name: 'Candidats',
    component: Candidats
  },
  {
    path: '/Candidats/:id',
    name: 'CandidatsDashboard',
    component: CandidatsDashboard
  },
  {
    path: '/password_change',
    name: 'PasswordChange',
    component: PasswordChange
  },
  {
    path: '/static',
    name: 'Static',
    component: Static
  },
  {
    path: '/all_company',
    name: 'AllCompany',
    component: AllCompany
  },
  {
    path:'/all_company/:id/edit',
    name:'CompanyEdit',
    component: CompanyEdit
  },
  /* Pages/Types/Positions */
  {
    path:'/PositionList',
    name:'PositionList',
    component: PositionList
  },
  {
    path:'/AddPosition',
    name:'AddPosition',
    component: AddPosition
  },
  {
    path:'/positionEdit',
    name:'positionEdit',
    component: positionEdit
  },
  /* Pages/Types/Jobs */
  {
    path:'/jobList',
    name:'jobList',
    component: jobList
  },
  {
    path:'/AddJob',
    name:'AddJob',
    component: AddJob
  },
  {
    path:'/jobEdit',
    name:'jobEdit',
    component: jobEdit
  }




]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {


  if (to.name === 'Signin' || to.name==='Signup' || to.name==='Recovery') {
    if(localStorage.getItem('user')){
      window.location.href='/Home';
    }else{
      next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    }
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (localStorage.getItem('user')) {

    next() // i'm logged in. carry on
  } else {
    if(to.name==='Signup'){
      next({ name: 'Signup' }) // always put your redirect as the default case
    }else{
      next({ name: 'Signin' }) // always put your redirect as the default case
    }

  }
})

export default router

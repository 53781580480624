<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
    <Header/>
    <div v-show="!isLoading" class="main" :class="this.$store.state.showTopMenu== true ? 'collapsed ' : 'uncollapsed'">
      <div class="container-fluid p-4">

        <div class="row mb-3">
          <!-- Col -->
          <div class="col-6">
            <h1 class="page-title">საქმიანობები</h1>
          </div>
          <!-- End Col -->
          <!-- Col -->
          <div class="col-6 text-end">
            <router-link to="/AddJob">
              <button class="btn btn-primary"> + დამატება</button>
            </router-link>
          </div>
          <!-- End Col -->
        </div>

        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center bg-light">
            საქმეების სია
            <input type="text" class="form-control w-50" placeholder="ძებნა">
          </div>
          <div class="card-body">

            <div class="row">
              <div class="col-12 mt-2">

                <table class="table">
                  <tbody>
                  <tr>
                    <td>
                      <div class="d-flex justify-content-between align-items-center">
                        საქმე
                        <!-- Action -->
                        <div>
                          <div class="btn btn-yellow me-1">
                            <span class="d-none d-md-block">რედაქტირება</span>
                            <i class="tio-edit d-block d-md-none"></i>
                          </div>
                          <div class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deletePosition">
                            <span class="d-none d-md-block">წაშლა</span>
                            <i class="tio-clear d-block d-md-none"></i>
                          </div>
                        </div>
                        <!-- End Action -->
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex justify-content-between align-items-center">
                        საქმე
                        <!-- Action -->
                        <div>
                          <div class="btn btn-yellow me-1">
                            <span class="d-none d-md-block">რედაქტირება</span>
                            <i class="tio-edit d-block d-md-none"></i>
                          </div>
                          <div class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deletePosition">
                            <span class="d-none d-md-block">წაშლა</span>
                            <i class="tio-clear d-block d-md-none"></i>
                          </div>
                        </div>
                        <!-- End Action -->
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>





          </div>
        </div>
      </div>
    </div>
    <!-- Delete Modal -->
    <div class="modal fade" id="deletePosition" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">პოზიციის წაშლა</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            ნამდვილად გსურთ პოზიციის წაშლა?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">გაუქმება</button>
            <button type="button" class="btn btn-danger">წაშლა</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "Index",
  components: {
    Header,
    Loading
  },
  data() {
    return {
      isLoading: false,
      fullPage: true
    }
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false
    }, 300);
  }
}
</script>

<style scoped>
.card-header {
  border-top-left-radius: 0.75rem!important;
  border-top-right-radius: 0.75rem!important;
}
</style>